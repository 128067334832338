import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import Layout from '../components/Layout';

export interface TherapistInfoPageProps {}

const TherapistInfoPage: React.FunctionComponent<TherapistInfoPageProps> = (): JSX.Element => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col className="text-center">
            <h1>Therapist Intro</h1>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TherapistInfoPage;
